@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

* {
  scroll-behavior: smooth;
}

.arFont {
  font-family: "Cairo", sans-serif;
}

.enFont {
  font-family: "Montserrat", sans-serif;
}

.navlink {
  margin-inline: 10px;
  transition: 0.5s;
  font-size: 18px;
  font-weight: 500;
  position: relative;
}
.transition {
  transition: 0.5s;
}

.navlinkEn {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 40px;
  width: 100%;
  border-bottom: solid 1px rgb(211, 211, 211);
  font-size: 19px;
}
.navlinkEn:hover {
  background-color: rgba(226, 226, 226, 0.4);
}
.navlinkEn p {
  margin-left: 10px;
}
.navlinkAr {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 40px;
  width: 100%;
  border-bottom: solid 1px rgb(195 195 195);
  font-size: 19px;
}
.navlinkAr p {
  margin-right: 10px;
}
.navlinkAr,
.navlinkEn {
  transition: 0.5s;
}
.navlinkAr:hover {
  background-color: rgba(226, 226, 226, 0.4);
}
.x,
.x button {
  transition: 0.5s;
}
.background-style {
  background-size: cover;
  background-position: center;
}

.h {
  min-height: 100vh;
}

.hide {
  transform: translateX(-100%);
  transition: all 1s;
}

.view {
  transform: translateX(0%);
  transition: all 0.7s;
}

.hideForAr {
  transform: translateX(100%);
  transition: all 1s;
}

.viewForAr {
  transform: translateX(0%);
  transition: all 0.7s;
}
.style-blend-mode {
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.5);
  background-blend-mode: multiply;
  background-position: center;
}
.style-blend-mode-homePage2{
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.5);
  background-blend-mode: multiply;
  background-position: bottom;
}
.style-blend-mode-contact {
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.5);
  background-blend-mode: multiply;
  background-position: center;
  min-height: 101vh;
}
.style-blend-mode-prouduct {
  background-position: center;
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.5);
  background-blend-mode: multiply;
  background-position: center;
}
.style-blend-mode-section-two {
  background-size: cover;
  background-color: rgba(255, 255, 255, 0.5);
}
.effect:hover {
  color: #00a44f;
}

.backgroundColorSideBar {
  background-color: white;
}
#nav2::before {
  content: "";
  position: absolute;
  z-index: 10;
  border-style: solid;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  transition: transform 0.5s;
}
.shadowR {
  box-shadow: 0px 0px 40px black;
}
.shadowL {
  box-shadow: 0px 0px 40px black;
}

.navlink0:before,
.navlink0:after {
  content: "";
  position: absolute;
  border-style: solid;
  left: 0;
  top: 0;
  transition: transform 0.8s;
}
.navlink0:after {
  border-width: 0px 0px 3px 0;
  height: 105%;
  width: 100%;
  border-color: #00a44f;
  transform: scale3d(0, 1, 0);
  transform-origin: left;
}
.navlink0:hover:before {
  transform: scale3d(1, 1, 1);
}

.navlink0:hover::after {
  transform: scale3d(1, 1, 1);
}

.slick-next {
  right: -50px;
  z-index: 5;
}
.slick-prev {
  left: -50px;
  z-index: 5;
}
.slick-next::before,
.slick-prev:before {
  font-size: 40px;
}

.slick-dots {
  bottom: 70px;
  margin-bottom: 20px;
}

.scallSportFlooring {
  transition: 0.5s;
  background-color: rgba(9, 95, 255, 0.3);
}
#sportFlooringLi:hover .scallSportFlooring {
  scale: 1.2;
  background-color: rgba(9, 95, 255, 0.65);
}

.scallcommirualIcon {
  transition: 0.5s;
  background-color: rgba(9, 255, 42, 0.3);
}

#sportFlooringLi2:hover .scallcommirualIcon {
  scale: 1.2;
  background-color: rgba(9, 255, 42, 0.65);
}

.scallhomeIcon {
  transition: 0.5s;
  background-color: rgba(217, 177, 77, 0.3);
}

#sportFlooringLi3:hover .scallhomeIcon {
  scale: 1.2;
  background-color: rgba(217, 177, 77, 0.65);
}
/*

.backGroundImageSportFlooring {
  background-image: url("./imges/sportsFlooring.jpg");
}
.backGroundImagesCallcommirualIcon {
  background-image: url("./imges/commirualFlooring.jpg");
}
.backGroundImageHomeFlooring {
  background-image: url("./imges/homeFlooring.jpg");
}*/
.bicInfo:before,
.bicInfo:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 0%;
  width: 0%;
  transition: transform 0.8s;
}

.bicInfo:after {
  height: 100%;
  width: 100%;
  z-index: 0;
  background-color: rgba(28, 75, 22, 0.6);
  transform: scale3d(0, 1, 1);
  transform-origin: left;
}

.bicInfo:hover:before {
  transform: scale3d(1, 1, 1);
}

.bicInfo:hover::after {
  transform: scale3d(1, 1, 1);
}
.contentDiv {
  transition: 0.5s;
}
.contentDiv:hover .div {
  visibility: hidden;
  opacity: 0;
  transition: 0.5s;
}
.div {
  visibility: visible;
  opacity: 1;
  transition: 0.5s;
}
.div2 {
  visibility: hidden;
  opacity: 0;
  transition: 0.5s;
}
.contentDiv:hover .div2 {
  visibility: visible;
  opacity: 1;
  transition: 0.5s;
}
.textV {
  font-size: clamp(20px, 3vw, 32px);
}
.backgroundColor {
  background-color: #000000f2;
  background-attachment: fixed;
  background-size: cover;
  background-blend-mode: multiply;
  height: 300px;
}
.style-blend-mode-for-sectionFour {
  background-size: cover;
  background-color: #f5f5f5;
}
.backgroundColorGallary {
  background-blend-mode: darken;
  background-color: #0000003a;
  border-radius: 5px;
  background-size: cover;
}

.rounded-border {
  /* Adjust width as needed */
  /* Adjust height as needed */
  border: 1px solid #d1d1d1; /* Border color and thickness */
  border-radius: 0px 70px 70px 70px; /* Border radius values for each corner (top-left, top-right, bottom-right, bottom-left) */
  background-color: rgb(255, 255, 255);
}
.gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* Adjust the gap between images */
}

.image {
  flex: 0 0 calc(33.33% - 10px); /* Each image takes up one-third of the container's width */
  height: 230px; /* Set the height of each image */
  transition: 0.5s;
}

/* Adjust the width of images for smaller screens */
@media (max-width: 768px) {
  .image {
    flex: 0 0 calc(100% - 10px); /* Each image takes up the full width of the container */
  }
}
/* Hover effect */
.image:hover {
  background-color: #000000d5; /* Change the background color on hover */
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2); /* Drop shadow effect */
}

.modal-image {
  max-width: 100%;
  max-height: 100%;
  display: block;
  margin: 0 auto;
}
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 10px;
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 4px;
  cursor: pointer;
  z-index: 999; /* Ensure it's on top of the modal content */
}

#icon{
  visibility: hidden;
  transition: all 0.1s;
}
.v:hover #icon{
  visibility: visible;
}
/* styles.css */


/* Apply styles when screen width is less than or equal to 326px */
@media (min-width: 100px) and (max-width: 326px)  {
  .myComponent {
    height: 150vb;
  }
}
.centered-div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.icon1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}